import { CookieService } from 'ngx-cookie-service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';
import { QueryService } from '../../services/query.service';
import { FormBuilder, Validators } from '@angular/forms';
import { IChatHistory, IQuery } from '../../shared/interfaces/query.interface';
import { faCopy, faHashtag, faTag } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { PromptService } from '../../services/prompt.service';
import { IPrompt } from '../../shared/interfaces/prompt.interface';

@Component({
    selector: 'app-redirect',
    templateUrl: './reset-url.component.html',
    styleUrls: ['./reset-url.component.scss']
})
export class ResetURLComponent implements OnInit, OnDestroy {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private cookieService: CookieService,
        private queryService: QueryService,
    ) {
    }
    ngOnInit(): void {
        console.log('DELETING SITE DATA');
        this.cookieService.delete('session');
        this.userService.clearSiteData().subscribe(val=> {
            console.log('CLEARING SITE DATA');
            setTimeout(() => {
                window.location.href = '/?access_token=cannes2024';
            },2000);
        });
    }
    ngOnDestroy() {
    }

}