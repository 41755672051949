import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertModule } from 'ngx-bootstrap/alert';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResetURLComponent } from './reset-url.component';
import { ResetRoutingModule } from './reset-routing.module';
import { Injectable } from '@angular/core';
import { QueryService } from '../../services/query.service';
import { ChatService } from '../../services/chat.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastrModule } from 'ngx-toastr';
import { SimulationModule } from '../../simulation/simulation.component.module';



@NgModule({
  declarations: [
    ResetURLComponent
  ],
  providers: [ QueryService, ChatService],
  imports: [
    CommonModule,
    RouterModule,
    ResetRoutingModule
  ],
  exports: [
    ResetURLComponent
  ]
})
export class ResetModule { }
